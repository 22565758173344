import { useState } from 'react';

// * MUI
import { Close, FileDownload } from '@mui/icons-material';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

// * Components
import { CloseIcon, Container, Content, Head, Modal, ModalActions, Wrapper, Value } from './components';
import { InputWrapper } from './InputWrapper';

// * Hooks & Utils
import { useCustomSnackbar } from '@/hooks';
import { showError } from '@/helpers';
import { useMutation, useQuery } from 'react-query';
import format from 'date-fns/format';

// * Services
import {
  fetchActivePackagesByCustomerId,
  fetchActiveProjectsByCustomerId,
  fetchAllBonuses,
  fetchAllCustomers,
  fetchAllStatuses,
  fetchInternalUsers,
  fetchRequestsByPackageIdOrProjectIdToSelect,
  generateJobImportExample,
} from '@/services';

interface IJobImportExampleProps {
  openExample: boolean;
  setOpenExample: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobImportExample = ({ openExample, setOpenExample }: IJobImportExampleProps) => {
  const snackbar = useCustomSnackbar();
  const { mutate } = useMutation(generateJobImportExample);

  const [customerId, setCustomerId] = useState<number | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [packageId, setPackageId] = useState<number | null>(null);
  const [requesterId, setRequesterId] = useState<number | null>(null);
  const [responsibleId, setResponsibleId] = useState<number | null>(null);
  const [statusId, setStatusId] = useState(1);
  const [bonusId, setBonusId] = useState<number | null>(null);
  const [requestId, setRequestId] = useState<number | null>(null);
  const [packageConsumption, setPackageConsumption] = useState<'' | '20:00'>('');
  const [budget, setBudget] = useState(false);
  const [packageDate, setPackageDate] = useState('');

  const getCustomers = useQuery('customers', fetchAllCustomers);
  const getProjects = useQuery(
    ['projects', { customerId }],
    async () => fetchActiveProjectsByCustomerId(customerId as number),
    { enabled: !!customerId },
  );
  const getPackages = useQuery(
    ['packages', { customerId }],
    async () => fetchActivePackagesByCustomerId(customerId as number),
    { enabled: !!customerId },
  );
  const getUsers = useQuery('internal-users', fetchInternalUsers);
  const getStatuses = useQuery('status', fetchAllStatuses);
  const getBonuses = useQuery('bonus', fetchAllBonuses);
  const getRequests = useQuery(
    ['requests-example', { packageId, projectId }],
    async () => fetchRequestsByPackageIdOrProjectIdToSelect(packageId, projectId),
    { enabled: !!(packageId ?? projectId) },
  );

  const handleToggle = () => setOpenExample(!openExample);

  const handleGenerate = () => {
    mutate(
      {
        customer: customerId as number,
        project: projectId ?? null,
        package: packageId ?? null,
        requester: requesterId as number,
        responsible: responsibleId as number,
        status: statusId,
        bonus: bonusId ?? null,
        request: requestId as number,
        estimated_time: '20:00',
        package_consumption: packageConsumption === '' ? null : packageConsumption,
        budget,
        package_date: packageDate === '' ? null : packageDate,
      },
      {
        onSuccess: (data) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(new Blob([data as Blob]));
          a.target = '_blank';
          a.setAttribute('download', 'import_jobs_example.xlsx');
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        },
        onError: (error: any) => showError({ error, snackbar, message: 'Erro ao gerar planilha' }),
      },
    );
  };

  const defaultPackageDate = () => format(new Date(), 'MM/yyyy');

  return (
    <Modal open={openExample} onClose={handleToggle}>
      <Container>
        <Head>
          <h1>Planilha modelo</h1>

          <CloseIcon onClick={handleToggle}>
            <Close />
          </CloseIcon>
        </Head>

        <Content>
          <InputWrapper
            onChange={(_, value) => {
              setCustomerId(value?.id);
              setProjectId(null);
              setPackageId(null);
              setRequestId(null);
            }}
            data={getCustomers.data}
            label="Selecione o cliente"
            value={customerId}
          />

          <InputWrapper
            onChange={(_, value) => {
              setProjectId(value?.id);
              setPackageConsumption(value ? '' : '20:00');
              setPackageId(null);
              setRequestId(null);
              setPackageDate('');
            }}
            data={getProjects.data}
            label="Selecione o projeto"
            value={projectId}
          />

          <InputWrapper
            onChange={(_, value) => {
              setPackageId(value?.id);
              setPackageConsumption(value ? '20:00' : '');
              setProjectId(null);
              setRequestId(null);
              setPackageDate(defaultPackageDate);
            }}
            data={getPackages.data}
            label="Selecione o pacote"
            value={packageId}
          />

          <InputWrapper
            onChange={(_, value) => setRequesterId(value?.id)}
            data={getUsers.data}
            label="Selecione o solicitante"
            value={requesterId}
          />

          <InputWrapper
            onChange={(_, value) => setResponsibleId(value?.id)}
            data={getUsers.data}
            label="Selecione o responsável"
            value={responsibleId}
          />

          <InputWrapper
            onChange={(_, value) => setRequestId(value?.id)}
            data={getRequests.data}
            label="Selecione o solicitação"
            value={requestId}
            optionName="title"
          />

          <InputWrapper
            onChange={(_, value) => setStatusId(value?.id ?? 1)}
            data={getStatuses.data}
            label="Selecione o status"
            value={statusId}
          />

          <InputWrapper
            onChange={(_, value) => {
              setBonusId(value?.id);
              setPackageConsumption(value ? '' : '20:00');
            }}
            data={getBonuses.data}
            label="Selecione o bônus"
            value={bonusId}
            optionName="reason"
          />

          <Wrapper>
            <div style={{ width: '75%' }}>
              <TextField disabled fullWidth label="Consumo de pacote (horas:minutos)" value={packageConsumption} />
            </div>

            <Value>{packageConsumption !== '' ? packageConsumption : 'Não informado.'}</Value>
          </Wrapper>

          <Wrapper>
            <FormControl sx={{ width: '75%' }}>
              <InputLabel id="select-budget-label">Orçamento</InputLabel>

              <Select
                labelId="select-budget-label"
                id="select-budget"
                value={budget}
                onChange={(e: SelectChangeEvent<any>) => {
                  const { value } = e.target;
                  setBudget(value);
                  setPackageConsumption(value ? '' : '20:00');
                }}
                label="Orçamento"
              >
                <MenuItem value={false as any}>Não</MenuItem>
                <MenuItem value={true as any}>Sim</MenuItem>
              </Select>
            </FormControl>

            <Value>{budget ? 'true' : 'false'}</Value>
          </Wrapper>

          <Wrapper>
            <div style={{ width: '75%' }}>
              <TextField disabled fullWidth label="Data do pacote" value={packageDate} />
            </div>

            <Value>{packageDate !== '' ? packageDate : 'Não informado.'}</Value>
          </Wrapper>
        </Content>

        <ModalActions>
          <Button variant="contained" onClick={handleGenerate} startIcon={<FileDownload />}>
            Gerar
          </Button>

          <Button variant="outlined" onClick={handleToggle} startIcon={<Close />}>
            Fechar
          </Button>
        </ModalActions>
      </Container>
    </Modal>
  );
};
