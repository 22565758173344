// * MUI
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, TextField } from '@mui/material';

// * Components
import { Wrapper, Value } from './components';

interface InputWrapperProps {
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => void;
  data: any[] | undefined;
  label: string;
  value: string | number | null;
  optionName?: string;
}

export const InputWrapper = ({ onChange, data, label, value, optionName = 'name' }: InputWrapperProps) => {
  return (
    <Wrapper>
      <Autocomplete
        disablePortal
        onChange={onChange}
        value={data?.find((item) => item.id === value) ?? null}
        getOptionLabel={(option) => option[optionName]}
        clearOnBlur
        clearOnEscape
        options={data ?? []}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option) => (
          <Box {...props} component="li" key={option.id} style={{ display: data ? 'flex' : 'none' }}>
            {option[optionName]}
          </Box>
        )}
      />

      <Value>{value ?? 'Não informado.'}</Value>
    </Wrapper>
  );
};
